@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-padding-top: 80px; /* Adjust for UH and Shophub header height */
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --link-color: #0f69ff;
  --uh-max-width: 1504px;
}

a {
  color: var(--link-color);
}

article [data-jump-link-target] {
  scroll-margin-top: 150px;
}

.knowledge .answer {
  h1, h2, h3, ol, ul {
    all: revert;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .text-balance {
    text-wrap: balance;
  }

  .stretched-box::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.site-shopping {
  .bg-site-gradient {
    background: linear-gradient(105deg, rgba(244, 235, 255, 0.80) 0%, rgba(255, 240, 241, 0.80) 58.4%, rgba(255, 250, 250, 0.80) 100.01%);
  }
}

.site-tech {
  .bg-site-gradient {
    background: linear-gradient(105deg, rgba(226, 230, 255, 0.80) 0%, rgba(223, 242, 255, 0.80) 58.4%, rgba(242, 250, 255, 0.80) 100.01%);
  }
}

.site-local {
  .bg-site-gradient {
    /* background: linear-gradient(105deg, rgba(226, 230, 255, 0.80) 0%, rgba(223, 242, 255, 0.80) 58.4%, rgba(242, 250, 255, 0.80) 100.01%); */
    background: linear-gradient(105deg, var(--spectrum-yellow-0, rgba(255, 253, 238, 0.80)) 0%, var(--spectrum-orange-0, rgba(255, 249, 242, 0.80)) 58.4%, var(--spectrum-orange-1, rgba(255, 239, 222, 0.80)) 100.01%), var(--palette-background-primary, #FFF);
  }
}

.site-health {
  .bg-site-gradient {
    background: linear-gradient(313deg, var(--spectrum-yellow-0, #FFFDEE) -27.49%, var(--spectrum-blue-1, #DFF2FF) 101.58%);
  }
}

.site-autos {
  .bg-site-gradient {
    background: linear-gradient(105deg, var(--spectrum-carbon-3, rgba(227, 227, 227, 0.70)) 0%, var(--spectrum-nude-0, rgba(255, 245, 245, 0.70)) 100.01%), var(--palette-background-primary, #FFF);
  }
}
